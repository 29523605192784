import { Router } from '@angular/router';
import { HttpService } from 'src/app/service/http.service';
import { AlertService } from './alert.service';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import Url from '../_helpers/Url';
@Injectable()
export class DataServiceClass {
  private _currentUser: any = {};
  private _quizData: any = null;
  private _attemptData: any = null;
  private _completeAttemptData: any = null;
  private _headerText: string = 'Exam Setter';
  private onDestroy$ = new Subject<boolean>();
  // public static $QUIZ_RESULT = ['passed' => 'PASSED','failed'=>'FAILED','quit'=>'QUIT'];
  private _examSummary: any = [{
    "label" : "All",
    "length" : 0,
    "questions": '',
    "list" : [],
    "currentIndex" : -2
  },{
    "label" : "Answers",
    "length" : 0,
    "questions": '',
    "list" : [],
    "currentIndex" : -2
  },{
    "label" : "Unanswer",
    "length" : 0,
    "questions": '',
    "list" : [],
    "currentIndex" : -2
  },{
    "label" : "Review",
    "length" : 0,
    "questions": '',
    "list" : [],
    "currentIndex" : -2
  }];

  constructor(private alertService : AlertService,private http : HttpService ,private router : Router) {
    this.userDetailObj();
    this.quizDataObj();
    this.attemptDetailsDataObj();
    this.CompleteAttemptDetailsDataObj();
  }

  public get examSummaryGet(): any {
    return this._examSummary;
  }
  public set examSummarySet(value: any) {
    this._examSummary = value;
  }

  //Header Text 
  public get headerTextGet(): string {
    return this._headerText;
  }
  public set headerTextSet(value: string) {
    this._headerText = value;
  }
  //Complete Attempt Data
  public get completeAttemptDataGet(): any {
    return this._completeAttemptData;
  }
  public set completeAttemptDataSet(value: any) {
    this._completeAttemptData = value;
    this.setLocal("Quiz_Users", "completeAttempt", this._completeAttemptData);
  }
  //Question Data
  public get attemptDataGet(): any {
    return this._attemptData;
  }
  public set attemptDataSet(value: any) {
    this._attemptData = value;
    this.setLocal("Quiz_Users", "attempt", this._attemptData);
  }
  //Quiz Data
  public get _quizDataGet(): any {
    return this._quizData;
  }
  public set _quizDataSet(value: any) {
    this._quizData = value;
    this.setLocal("Quiz_Users", "quiz", this._quizData);
  }
  //currentUser Data
  public get currentUserGet(): any {
    return this._currentUser;
  }
  public set currentUserSet(value: any) {
    this._currentUser = value;
    this.setLocal("Quiz_Users", "User", this._currentUser);
  }
  //Init User data
  private userDetailObj() {
    let userDetails = this.getLocal('Quiz_Users', 'User');
    if (userDetails != undefined) {
      this._currentUser = userDetails;
    } else {
      this._currentUser = null;
    }
  }
  //Init Quiz data
  private quizDataObj() {
    let quizDetails = this.getLocal('Quiz_Users', 'quiz');
    if (quizDetails != undefined) {
      this._quizData = quizDetails;
    } else {
      this._quizData = null;
    }
  }
  //Init Question data
  private attemptDetailsDataObj() {
    let attemptDetails = this.getLocal('Quiz_Users', 'attempt');
    if (attemptDetails != undefined) {
      this._attemptData = attemptDetails;
    } else {
      this._attemptData = null;
    }
  }
  //Init Complete Attempt data
  private CompleteAttemptDetailsDataObj() {
    let CompleteAttemptDetails = this.getLocal('Quiz_Users', 'completeAttempt');
    if (CompleteAttemptDetails != undefined) {
      this._completeAttemptData = CompleteAttemptDetails;
    } else {
      this._completeAttemptData = null;
    }
  }
  //clear DataService
  clearDataService() {
    this._currentUser = null;
    this._quizData = null;
    this._attemptData = null;
    this._completeAttemptData = null;
  }
  //Set module to localstorage
  setLocal(module: string, key: string, data: any) {
    // this.removeLocal(module , key);
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
    }
    modObj[key] = data;
    localStorage.setItem(module, JSON.stringify(modObj));
  }
  //Get module from localstorage
  getLocal(module: string, key: string) {
    let modObj = JSON.parse(localStorage.getItem(module));
    if (modObj == undefined) {
      modObj = {};
      localStorage.setItem(module, JSON.stringify(modObj));
    }
    if (key != undefined) return modObj[key];
    return modObj;
  }
  //Remove module from localstorage
  removeLocal(module: string, key: string) {
    if (key != undefined) {
      let modObj = JSON.parse(localStorage.getItem(module));
      if (modObj != undefined) {
        delete modObj[key];
        localStorage.setItem(module, JSON.stringify(modObj));
        return;
      }
    }
    localStorage.removeItem(module);
  }
  //Filter Object 
  public filter(reqObj) {
    Object.keys(reqObj).forEach(function (key) {
      if (reqObj[key] == null || reqObj[key] == "string" || reqObj[key] == "undefined") {
        delete reqObj[key];
      }
    });
    return reqObj;
  }

  public completeAttempt() {
    let url = Url.Complete_Attempt_Quiz_Post_URL.replace("{id}", this._quizDataGet.id).replace("{a_id}",
      this.attemptDataGet.id);
    this.http.postData({"type" : "timeover"}, url).pipe().subscribe(response => {
      if (response.status === 200 && response.result != null) {
        this.completeAttemptDataSet = response.result;
        // this.alertService.showToast("success", "Exam Attempt Succefully Submitted");
        this.router.navigate(['/user/congratulation']);
      }
    })
  }
  
  public shareOnWhatsApp(message: string, mobile: any) {
    if (message == undefined || message == "") return;
    if (mobile == undefined) {
      window.location.href = "whatsapp://send?text=" + encodeURIComponent(message);
    } else {
      window.location.href = "whatsapp://send?phone=91" + mobile + "&text=" + encodeURIComponent(message);
    }
  }
  
  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
