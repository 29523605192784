import { AlertService } from './../../service/alert.service';
import { tap } from 'rxjs/internal/operators/tap';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServiceService } from '../service/auth-service.service';
import AOS from 'aos';
import { Router } from '@angular/router';
declare let $: any;
import { NbDialogService } from '@nebular/theme';
import { ConfirmComponent } from 'src/app/popup/confirm/confirm.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registerForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private auth: AuthServiceService, private alertService: AlertService,
    private router: Router, private dialogService: NbDialogService) {
    AOS.init();
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
      sabhaname: ['Sabha Name', [Validators.required]],
    });
  }

  get registerFormControls() { return this.registerForm.controls; }

  public registerUser() {
    if (this.registerForm.value.sabhaname == 'Sabha Name') {
      this.registerForm.controls['sabhaname'].setErrors({'required': true});
    }
    if (this.registerForm.invalid) return this.registerForm.markAllAsTouched();
    this.auth.register(this.registerForm.value).pipe(tap()).subscribe(response => {
      if (response.status === 200) {
        this.dialogService.open(ConfirmComponent)
        // setTimeout(() => {
        //   this.router.navigate(['/login']);
        // }, 1000);
      }
    })
  }
}
