import { environment } from 'src/environments/environment';

export default class Url {
   static imagePath: string = `${environment.uploadImageUrl}`;
   //Auth Api
   static Verify_Mobile_Post_URL: string = `${environment.Api_Base_Url + `${'v1/user/verify'}`}`;
   static Login_URL: string = `${environment.Api_Base_Url + `${'v1/user/login'}`}`;
   static Register_User_URL: string = `${environment.Api_Base_Url + `${'v1/user/register'}`}`;
   //Quiz Api
   static Quiz_List_Get_URL_Open: string = `${environment.Api_Base_Url + `${'v1/user/quizs'}`}`;
   static Quiz_Get_By_Id: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{id}'}`}`;
   static Quiz_Result_Get_URL_Open: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{id}/result'}`}`;
   static Quiz_List_Get_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/list'}`}`;
   //Attempt Api
   static Attempt_Quiz_Post_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{id}/attempt'}`}`;
   static Complete_Attempt_Quiz_Post_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{id}/attempt/{a_id}/action/complete'}`}`;
   //Question Api
   static Quiz_Question_List_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{q_id}/attempt/{a_id}/questions'}`}`;
   static Quiz_Question_Option_List_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{q_id}/attempt/{a_id}/questions/{que_id}/options'}`}`;
   static Quiz_Question_Attempt_Answer_URL: string = `${environment.Api_Base_Url + `${'v1/user/quiz/{q_id}/attempt/{a_id}/answer'}`}`;
   //Dashboard Api
   static Dashboard_URL: string = `${environment.Api_Base_Url + `${'v1/user/report/event/3/dashboard'}`}`;
   static Leaderboard_URL: string = `${environment.Api_Base_Url + `${'v1/user/report/event/3/team/performance'}`}`;
   // static Leaderboard_URL: string = `${environment.Api_Base_Url + `${'v1/user/report/team/1/performance?event_id=3'}`}`;
   static Dashboard_Feed_URL: string = `${environment.Api_Base_Url + `${'v1/user/event/3/task'}`}`;
   static Complete_Task_URL: string = `${environment.Api_Base_Url + `${'v1/user/event/3/task/{task_id}/complete'}`}`;
   // Analytics Api
   static Analytics_Table_URL: string = `${environment.Api_Base_Url + `${'v1/user/report/event/3/task'}`}`;
   static Task_Wise_Member__Data_URL: string = `${environment.Api_Base_Url + `${'v1/user/report/event/3/task/{task_id}/member'}`}`;
   static My_Progress_URL: string = `${environment.Api_Base_Url + `${'v1/user/event/3/task'}`}`;
   static My_Progress_Details_URL: string = `${environment.Api_Base_Url + `${'v1/user/event/3/task/{task_id}/quiz/{quiz_id}/attemptdetails'}`}`;
   
}