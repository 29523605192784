

import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  public _listnerGlobal = new Subject<any>();

  constructor() { }

  /* Global Share Data */
  globalRecieve(): Observable<any> {
    return this._listnerGlobal.asObservable();
  }

  globalSend(filterBy) {
    this._listnerGlobal.next(filterBy);
  }
  /* Global Share Data Ends */

}
