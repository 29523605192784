import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/authentication/service/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private _authService: AuthServiceService, private _router: Router) { }

    canActivate(): boolean {
        if (this._authService.currentUserValue) {
            this._router.navigate(['/user/dashboard'])
            return false
        } else {
            return true
        }
    }
  
}
