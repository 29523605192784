<div class="overlay active"></div>
<div class="pop-up-modal active" id="pop-up">
    <div class="pop-up-wrap">
        <img src="assets/image/01-account-created-dribbble.gif" alt="Celebration">
        <h2 class="text-center">Congratulations</h2>
        <!-- <h4>You have got 100 Pts.</h4> -->
        <!-- <h5 class="mt-3 text-center">Please Wait for 48 hrs. Let us verify your account!</h5> -->
        <p class="sign-up-link"><a [routerLink]="['/login']"
            routerLinkActive="router-link-active">Login!</a></p>
    </div>
</div>