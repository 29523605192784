import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
// import { AngularFireDatabaseModule } from '@angular/fire/database';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbButtonModule, NbSidebarModule, NbMenuModule, NbDatepickerModule, NbDialogModule, NbWindowModule, NbToastrModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ThemeModule } from './@theme/theme.module';
import { CoreModule } from './@core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AuthenticationModule } from './authentication/authentication.module';
import { HttpService } from './service/http.service';
import { AlertService } from './service/alert.service';
import { DataServiceClass } from './service/DataService.service';
import { BasicAuthInterceptor } from './_helpers/http.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';
import { NgxUiLoaderDemoService } from './service/ngx-loader.service';
import { LoaderComponent } from './components/loader/loader.component';
import { ConfirmComponent } from './popup/confirm/confirm.component';
import { GlobalDataService } from './service/global-data.service';
// import { MessagingService } from './service/messaging.service';
// import { environment } from '../environments/environment';
// import { AsyncPipe } from '../../node_modules/@angular/common';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#ff6600",
  bgsOpacity: 0.7,
  bgsPosition: "center-center",
  bgsSize: 150,
  bgsType: "ball-scale-multiple",
  blur: 7,
  delay: 0,
  fgsColor: "#ff6600",
  fgsPosition: "center-center",
  fgsSize: 150,
  fgsType: "ball-scale-multiple",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 200,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: true,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 500
};
@NgModule({
  declarations: [
    AppComponent,LoaderComponent, ConfirmComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbButtonModule,
    NbEvaIconsModule,
    AuthenticationModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    CommonModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    // AngularFireDatabaseModule,
    //   AngularFireAuthModule,
    //   AngularFireMessagingModule,
    //   AngularFireModule.initializeApp(environment.firebase)
  ],
  exports : [NgxUiLoaderRouterModule,NgxUiLoaderModule],
  providers: [
    HttpService,
    AlertService,
    NgxUiLoaderDemoService,
    DataServiceClass,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    GlobalDataService,
    // MessagingService,
    // AsyncPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
