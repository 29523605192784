import { Component, HostListener } from "@angular/core";
// import { MessagingService } from "./service/messaging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Utkarsh";
  message;
  constructor(
    // private messagingService: MessagingService
    ) {}

  ngOnInit(): void {
    // const userId = "user001";
    // this.messagingService.requestPermission(userId);
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;
  }
}
