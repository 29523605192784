import { DataServiceClass } from "src/app/service/DataService.service";
import { Component, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { LayoutService } from "../../../@core/utils";
import { map, takeUntil, filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { GlobalDataService } from "src/app/service/global-data.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  public route: any;
  public countDownMin: number = 0;
  public countDownSec: number = 0;
  currentTheme = "default";
  interval: NodeJS.Timeout;
  timeLeft: any;
  my_rank: any;
  totalScore: any;
  toggleMenu = false;
  constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public dataServiceClass: DataServiceClass,
    private router: Router,
    private globalData: GlobalDataService
  ) {
    this.initRouter();
    this.globalData.globalRecieve().subscribe((obj: any) => {
      if (obj.key == "my_rank") {
        this.my_rank = obj.val.rank;
        this.totalScore = obj.val.totalScore;
      }
    });
  }
  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
      });

    // this.my_rank = this.globalData.getData()
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();
    return false;
  }

  onExam() {
    this.router.navigate(["register"]);
  }

  initRouter() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url;
        if (this.route == "/user/exam-paper") {
          setTimeout(() => {
            this.startTimer();
          }, 500);
        } else {
          clearInterval(this.interval);
        }
      }
    });
  }

  startTimer() {
    if (
      this.dataServiceClass._quizDataGet != null &&
      this.dataServiceClass._quizDataGet.duration_in_min != null
    ) {
      this.timeLeft = this.dataServiceClass._quizDataGet.duration_in_min * 60;
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          this.displayTime();
        } else {
          clearInterval(this.interval);
          this.dataServiceClass.completeAttempt();
        }
      }, 1000);
    }
  }

  displayTime() {
    this.countDownMin = parseInt("" + this.timeLeft / 60);
    this.countDownSec = this.timeLeft % 60;
  }

  public logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    // this.dataServiceClass.clearDataService();
    this.router.navigate(["login"]);
  }

  onClick() {
    if (this.route == "/leaderboard") this.router.navigate(["/"]);
  }

  analytics() {
    this.router.navigate(["/user/analysis"]);
  }
  dashboard() {
    this.router.navigate(["/user/dashboard"]);
  }
}
