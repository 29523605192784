import { AlertService } from './../../service/alert.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServiceService } from '../service/auth-service.service';
import AOS from 'aos';
declare let $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public showPassword: boolean = false;
  constructor(private router: Router, private formBuilder: FormBuilder, private auth: AuthServiceService
    , private alertService: AlertService) {
    AOS.init();
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      identity: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

  get loginFormControls() { return this.loginForm.controls; }

  public login() {
    if (this.loginForm.invalid) return this.loginForm.markAllAsTouched();
    this.auth.login(this.loginForm.value).pipe(tap()).subscribe(response => {
      if (response.status === 200) {
        this.router.navigate(['user/dashboard'])
      }
    }, errorResponse => {
      if (errorResponse.status === 404) {
        this.alertService.showToast("danger", errorResponse.error.error.error);
        this.router.navigate(['register']);
      }
    })
  }
}
