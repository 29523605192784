import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from 'src/app/authentication/service/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthServiceService, public router: Router) { }
  canActivate(): boolean {
    if (!this.auth.currentUserValue) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

}
