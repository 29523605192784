// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  Api_Base_Url: 'https://api.utkarsh.leo9studio.in/api/',
  uploadImageUrl: 'https://api.utkarsh.leo9studio.in/storage',
  firebase: {
    apiKey: "AIzaSyD-t8iTZz1NUG5CfOoypouobAYW7Q7_O1k",
    authDomain: "utkarsh-season-3.firebaseapp.com",
    databaseURL: "https://utkarsh-season-3-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "utkarsh-season-3",
    storageBucket: "utkarsh-season-3.appspot.com",
    messagingSenderId: "543912311099",
    appId: "1:543912311099:web:33e3b9525a3075fc93df1e",
    measurementId: "G-SJXX1B5WJH"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
