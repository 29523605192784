<nb-layout>
  <nb-layout-column>
    <!-- <video autoplay muted loop id="myVideo" loading="lazy">
          <source src="./assets/galaxy-GT9V7UC.mp4" type="video/mp4">
        </video> -->
    <div id="wrapper" ontouchstart="">
      <svg class="bgSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 600" preserveAspectRatio="xMaxYMin meet">
        <defs>
          <linearGradient id="gradient_melt2" x1="50%" y1="0%" x2="50%" y2="100%">
            <stop offset="0%" style="stop-color:rgb(255, 255, 255);stop-opacity:0" />
            <stop offset="20%" style="stop-color:rgb(255, 255, 255);stop-opacity:0.3" />
            <stop offset="40%" style="stop-color:rgb(255, 255, 255);stop-opacity:1" />
          </linearGradient>
          <filter id="filter_sunBlur" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
          </filter>
          <linearGradient id="gradient_cloud1" x1="658.39" y1="-55.99" x2="646.49" y2="574.71"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#FFF4FF" />
            <stop offset="0.2" stop-color="#FBF3FE" />
            <stop offset="0.38" stop-color="#F0F0FC" />
            <stop offset="0.56" stop-color="#DDECF7" />
            <stop offset="0.73" stop-color="#C3E5F2" />
            <stop offset="0.91" stop-color="#A1DDEA" />
            <stop offset="1" stop-color="#8BD7E5" />
          </linearGradient>
          <linearGradient id="gradient_cloud2" x1="623.27" y1="84.56" x2="628.08" y2="789.71"
            xlink:href="#gradient_cloud1" />
          <linearGradient id="gradient_cloud3" x1="624.83" y1="567.32" x2="624.83" y2="818.88"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#FFF4FF" />
            <stop offset="1" stop-color="#DED7E5" />
          </linearGradient>
          <path id="cloud"
            d="M1429.3,399.4a98.2,98.2,0,0,0-97.1-98.2,42.6,42.6,0,0,0-81.6,17,43.7,43.7,0,0,0,4.5,19.1,97.8,97.8,0,0,0-22.1,62.1,92.3,92.3,0,0,0,.7,11.8,98.2,98.2,0,0,0-82,59.1,41.8,41.8,0,0,0-16.6-3.4,42.6,42.6,0,0,0-42.6,42.6,40.7,40.7,0,0,0,2.3,13.5,76,76,0,0,0-69,57,44,44,0,0,0-8.3-.9A39.5,39.5,0,0,0,987,593.5a113.1,113.1,0,0,0-81.7-34.8h-4.1a42.4,42.4,0,0,0-74.8-5.9,39.3,39.3,0,0,0-45.1,6.4,98.2,98.2,0,0,0-167.4-49.2A75.8,75.8,0,0,0,559,534.7a94,94,0,0,0-16.9-1.5A97.5,97.5,0,0,0,471.8,563a39.5,39.5,0,0,0-68,12.5,116.8,116.8,0,0,0-28.2-12.4c.3-2.2,8.4-50.1-42-49.4a43,43,0,0,0-40.8,30.5,96.3,96.3,0,0,0-10.8-.7,87.8,87.8,0,0,0-40.9,10.1,87.8,87.8,0,0,0-138-68A98.3,98.3,0,0,0,16,411.2a115,115,0,0,0,.7-11.8A98.1,98.1,0,0,0-45,308.2V591.8c.6.4,0,63.2,0,63.2H1314.5a113.3,113.3,0,0,0-21.5-62.1c28.2-17.3,46.8-91.8,46.3-95.7A98.2,98.2,0,0,0,1429.3,399.4Z" />
        </defs>
        <!-- CLOUDS -->
        <use xlink:href="#cloud" id="cloud1" fill="url(#gradient_cloud1)" x="-40" y="-100" />
        <use xlink:href="#cloud" id="cloud2" fill="url(#gradient_cloud2)" x="40" y="-60" />
        <use xlink:href="#cloud" id="cloud3" fill="url(#gradient_cloud3)" x="0" y="0" />
        <!--  S  U  N -->
        <g id="sun">
          <circle id="shine" filter="url(#filter_sunBlur)" fill="rgba(255,206,44,0.6)" cx="332" cy="32" r="183" />
          <path id="s5" fill="#FF952C"
            d="M500.7-59.8c-14.1-12-34.2-8.5-50.8-12.7s-29.8-14.2-41.7-27c-25-27.3-47.1-70.1-89.9-42.5-16.4,10.5-28,27-44.5,37.8s-33.1,16-51.1,21.8c-16.3,5.3-37.7,11.1-46.8,27.3-10.6,18.8,3.3,37.2,4.6,56.3,2.6,37.5-37.9,65.9-30.2,103.8,7,34.4,59.2,37.6,81.2,60.2,27,27.7,37.4,66.5,83.7,48.7,16.8-6.4,30.7-18.7,47.8-24.4,23.1-7.8,47.2-3.8,71-6.2,54.8-5.4,45.5-63.3,52.2-104.4,4.2-25.9,11.7-50.4,19.7-75.4C512.4-16.4,519.4-43.7,500.7-59.8Z" />
          <circle id="s4" fill="#FFCB4A" cx="332.4" cy="36.3" r="135.8" />
          <path id="s3" fill="#FFDC82"
            d="M426.8-27c-19.4-43.1-64.1-63.7-109.6-58-47.7,5.8-88,34.1-100.9,82.8-6.3,23.8,5.1,47.8,22.6,63.8,8.7,8,19.2,13,28.8,19.6s16.8,16,24.2,26.1c25.7,35.3,75.5,33.7,108,8.6C441,84.2,447.2,18.3,426.8-27Z" />
          <path id="s2" fill="#FFE6A6"
            d="M416.5-8.3c-4.4-40-37.6-62.6-74.9-66.7-15.7-1.8-34.8-2.1-48.5,6.6C274.4-56.5,276.2-37.8,278.2-19s-5,39.8,11.6,53.4c12.8,10.5,37,19.3,52.7,23.4C385.6,69.1,421.1,34.7,416.5-8.3Z" />
          <path id="s1" fill="#FFEEC2"
            d="M375.3,23.5c-5.1,16.7-28,24.5-51,17.4s-37.7-26.3-32.5-43,27.9-24.6,51-17.5S380.4,6.7,375.3,23.5Z" />
        </g>
        <!-- S M I L    A N I M A T I O N -->
        <!-- SUN -->
        <!-- <animateTransform xlink:href="#s5" attributeName="transform" type="rotate" dur="18s" begin="0"
                    repeatCount="indefinite" to="360" /> -->
        <animateTransform xlink:href="#s4" attributeName="transform" type="translate" dur="4s" begin="0"
          repeatCount="indefinite" values="0 0 ; 0 -15 ; 0 0" />
        <animate xlink:href="#s3" attributeName="d" dur="4s" begin="0" fill="freeze" repeatCount="indefinite"
          values="M426.8-27c-19.4-43.1-64.1-63.7-109.6-58-47.7,5.8-88,34.1-100.9,82.8-6.3,23.8,5.1,47.8,22.6,63.8,8.7,8,45.6,35.6,53,45.7,25.7,35.3,75.5,33.7,108,8.6C441,84.2,447.2,18.3,426.8-27Z
                  ;
                  M426.8-27c-19.4-43.1-64.1-63.7-109.6-58C269.5-79.2,241.9-49.7,229-1c-6.3,23.8-1.5,36,16,52,8.7,7.9,3,9,46.5,38.5,36.2,24.5,75.5,32.5,99,0C420.9,47.4,447.2,18.3,426.8-27Z
                  ;
                  M426.8-27c-19.4-43.1-64.1-63.7-109.6-58-47.7,5.8-88,34.1-100.9,82.8-6.3,23.8,5.1,47.8,22.6,63.8,8.7,8,45.6,35.6,53,45.7,25.7,35.3,75.5,33.7,108,8.6C441,84.2,447.2,18.3,426.8-27Z" />
        <!-- <animateTransform xlink:href="#s2" attributeName="transform" type="rotate" dur="8s" begin="0"
                    repeatCount="indefinite" to="360" /> -->
        <!-- C L O U D S -->
        <animateTransform xlink:href="#cloud1" attributeName="transform" type="translate" dur="9s" begin="0"
          repeatCount="indefinite" values="0 0 ; 35 25 ; 0 0" />
        <animateTransform xlink:href="#cloud2" attributeName="transform" type="translate" dur="4s" begin="2"
          repeatCount="indefinite" values="0 0 ; 25 15 ; 0 0" />
        <animateTransform xlink:href="#cloud3" attributeName="transform" type="translate" dur="5s" begin="0.6"
          repeatCount="indefinite" values="0 0 ; -15 -5 ; 0 0" />
      </svg>
      <!-- <div class="btn btn_Fullscreen" id="fullscr">Go Fullscreen</div> -->
    </div>
    <!-- <div id="black-overlay"></div> -->
    <main>
      <div class="auth-ct">
        <div class="auth-form-ct">
          <!-- <div class="head-image">
                  <img src="./img/swamiji.JPG" alt="">
                </div> -->
          <div data-aos="zoom-in" data-aos-duration="1000" class="logo-head logo-small">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 785.46 223.86">
              <defs>
                <style>
                  .cls-1 {
                    fill: #f76f34;
                  }

                  .cls-2 {
                    fill: #606060;
                  }

                </style>
              </defs>
              <circle class="cls-1" cx="70.5" cy="77.34" r="10.69" />
              <circle class="cls-1" cx="154.28" cy="77.34" r="10.69" />
              <path class="cls-2"
                d="M97.52,207.29c-73,0-102.46-63-96.85-127,1.12-12.77,4.74-31.81,14-42.18A9.34,9.34,0,0,1,26.8,36.29c3.86,2.62,6.16,8.62-4.34,40.92-6.78,20.86,6.71,73.15,21.37,88.41,30.44,31.69,85,24,116.71-1.9,27.35-22.38,39.13-58.85,41.29-94,.63-10.16,2.77-21.07.34-30.95-5.09-20.63,13.54-25.67,16-11.7C232.58,110.52,190.28,207.29,97.52,207.29Z" />
              <path class="cls-2"
                d="M257,223.77c-33.72-4.06,3.8-83.8-8.64-99.4-18.75-6.55-35.89-1.56-18.3-18.25,1.82-1.67,5.65-1.15,10.39-1.23,6.54.19,13,2.42,18.21-.91,5.11-3,7-44.93,17.35-60.16,7.3-10.76,14,11.42,13.22,15-3.19,14.2-8.39,29.6-11.74,44.5-1.88,8.36,27.21-5.8,29.59,7,.61,3.28,3.22,10.45-1.94,11.45-9.52,1.85-32.62-3.57-35.05,11.8-3.06,19.29-8.7,38.13-8.75,58.2,0,7.65,4.54,14.46,2.67,24.34C263.21,220.28,263.07,224.51,257,223.77Z" />
              <path class="cls-2"
                d="M373.37,207.29c-3,.68-3.62-.52-5.82-2.71a41.45,41.45,0,0,1-4.71-5.54c-3.84-4.76-9.37-6.64-13.34-11.69-6.65,8.59-20.47,22.86-32.47,14-19-12.41,7-226.09,29.09-176.29,3,9.36.8,15.67-.57,25.4-2.87,38.58-14,73.59-13.92,112.84-5.77,45.39,20.39-34.1,43.86-28.2,9.79,2,4.29,7.25.58,12.22-5.93,10-16.77,16.74-14.79,24.58C371.93,182.65,402,200.68,373.37,207.29Z" />
              <path class="cls-2"
                d="M477.33,204.94c-7.17.34-3.75-14.17-11.15-16.57-17.05-5.52-38.32,20.22-56.2,15.8-53.92-16.89,23.29-91,59.53-62.35,6.39,5.06,8.77,25.85-.74,20.74-2.73-1.47-3.46-1.73-8.12-4.3-6.48-3.57-6.91-5.6-10.45-4.8-15.29,3.47-36.27,14-37.87,27.87-1.7,14.79,44.34-13.85,56.28-12.33,9.82,1.24,13.16,10.41,16.34,18.42C488.22,195.67,485.08,204.56,477.33,204.94Z" />
              <path class="cls-2"
                d="M503.57,215c-26.31,4.71,1.76-67.2,21.53-74.65,12.28-4.63,24.43-6.91,38-2.15,10.55,3.69,19.26,15,6.36,22.32-6,3.42-7-2.94-12-4.21-9.33-2.37-20.14-.91-28.2,4.93C512,173.77,522.42,211.65,503.57,215Z" />
              <path class="cls-2"
                d="M608.69,210.32c-9,.57-10.83.29-15.44-7.73-6.73-11.7,8.51-9.58,16.27-11.06,8-1.55,39.37-6.79,27.91-13.14-14-7.77-31.6-9.87-45.32-18.14-10.85-6.55-8.35-23.27,3-27.43,15.12-5.54,33.22-5.35,48.48-2.48,24.16,4.53,3.39,21-8.17,19.42-3.89-.53-25.55-2.55-20.2.56,15.45,9,46.07,7.32,46.42,30.08C662,204.14,628,209.09,608.69,210.32Z" />
              <path class="cls-2"
                d="M773,223.44c-12.23-2.68-3.19-31.72-11.65-41.78-14.51-17.24-50.41-4.34-62.32,10.49-12.76,15.88-32.1,15.3-29.95-7.37,5.21-55,13.51-116.31,23.37-166.13C697.54-7,708.82-4.92,710.29,17c.62,9.29,1.34,9.06-.2,16.37-5.81,27.55-10.15,62.55-15.08,91.69-2.84,16.78-3.06,24.24-6.17,48.32.56,2.29,6.49-3.64,10.19-5.92,27.9-12.47,72.46-21.7,83.22,16.3,2.42,8.54,2.7,17.84,3.19,26.23C785.84,216.65,778.88,224.72,773,223.44Z" />
            </svg>
          </div>
          <div data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800" class="enter">
            <div class="w100 pr0 pl0">
              <form id="login" class="login-open2" [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="form-grp">
                  <input class="form-field" type="email" formControlName="identity" id="email" name="email"
                    autocomplete="email" spellcheck="true" tabindex="2" aria-required="true" placeholder="Email"
                    required />
                  <div *ngIf="loginFormControls.identity.invalid && loginFormControls.identity.touched">
                    <span class="text-danger" *ngIf="loginFormControls.identity.errors.required">This
                      field is required</span>
                    <div class="text-danger" *ngIf="loginFormControls.identity.errors.email">Email must be a valid email
                      address</div>
                  </div>
                </div>
                <div class="form-grp  pass-field">
                  <input id="password-field" class="form-field" formControlName="password"
                    [type]="showPassword ? 'text' : 'password'" id="password" name="password"
                    autocomplete="new-password" spellcheck="true" tabindex="3" aria-required="true"
                    placeholder="Password" required />
                  <div *ngIf="loginFormControls.password.invalid && loginFormControls.password.touched">
                    <span class="text-danger" *ngIf="loginFormControls.password.errors.required">This
                      field is required</span>
                  </div>
                  <span toggle="#password-field" class="form-icon toggle-password"
                    (click)="showPassword = !showPassword">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <path d="M0,0H24V24H0Z" fill="none" />
                      <circle cx="2" cy="2" r="2" transform="translate(10 10)" fill="none" stroke="#2c3e50"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                      <path d="M22,12q-4,7-10,7T2,12Q6,5,12,5t10,7" fill="none" stroke="#2c3e50" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1" />
                    </svg>
                  </span>
                </div>
                <!-- <button class="button button-line button--block" role="button" tabindex="4" type="submit"><span>Log
                    In</span></button> -->
                <!-- <div class="buttons">
                  <button role="button" tabindex="4" type="submit" class="btn">
                    <span></span>
                    <p data-title="Login" data-text="Go!" data-start="good luck!"></p>
                  </button>
                </div> -->
                <button class="btn btn-primary w-100" role="button" tabindex="4" type="submit">Login</button>
                <p class="sign-up-link">Don't have an account? <a [routerLink]="['/register']"
                    routerLinkActive="router-link-active">Join Us!</a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>

  </nb-layout-column>
</nb-layout>
