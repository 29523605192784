import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout>
      <nb-layout-header fixed [ngClass]="{ 'custom' : routes == '/congratulation'}">
      <!-- ,'layout-container' : routes == '/congratulation' -->
        <ngx-header ></ngx-header>
      </nb-layout-header>
      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
  `,
})
export class OneColumnLayoutComponent {
  routes: string;
  constructor(private routers: Router) {
    this.routers.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routes = event.url;
      }
    });
  }
}