<ngx-ui-loader
  [fgsColor]="ngxUiLoaderDemoService.config.fgsColor"
  [fgsPosition]="ngxUiLoaderDemoService.config.fgsPosition"
  [fgsSize]="ngxUiLoaderDemoService.config.fgsSize"
  [fgsType]="ngxUiLoaderDemoService.config.fgsType"
  [bgsColor]="ngxUiLoaderDemoService.config.bgsColor"
  [bgsOpacity]="ngxUiLoaderDemoService.config.bgsOpacity"
  [bgsPosition]="ngxUiLoaderDemoService.config.bgsPosition"
  [bgsSize]="ngxUiLoaderDemoService.config.bgsSize"
  [bgsType]="ngxUiLoaderDemoService.config.bgsType"
  [gap]="ngxUiLoaderDemoService.config.gap"
  [logoPosition]="ngxUiLoaderDemoService.config.logoPosition"
  [logoSize]="ngxUiLoaderDemoService.config.logoSize"
  [logoUrl]="ngxUiLoaderDemoService.config.logoUrl"
  [overlayColor]="ngxUiLoaderDemoService.config.overlayColor"
  [pbColor]="ngxUiLoaderDemoService.config.pbColor"
  [pbDirection]="ngxUiLoaderDemoService.config.pbDirection"
  [pbThickness]="ngxUiLoaderDemoService.config.pbThickness"
  [hasProgressBar]="ngxUiLoaderDemoService.config.hasProgressBar"
  [text]="ngxUiLoaderDemoService.config.text"
  [textColor]="ngxUiLoaderDemoService.config.textColor"
  [textPosition]="ngxUiLoaderDemoService.config.textPosition"></ngx-ui-loader>