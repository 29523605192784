<!-- <div class="header-container w-100">
    <div class="row m-0 w-100">
        <div class="col-3 pl-0">
            <nb-actions size="medium">
                <nb-action class="control-item pl-0" icon="arrow-ios-back-outline" (click)="onClick()" *ngIf="route ==  '/leaderboard'"></nb-action>
            </nb-actions>
        </div>
        <div class="col-6 d-flex justify-content-center align-items-center pr-0 pl-0 d-flex">
            <h5 class="mb-0" *ngIf="route != '/exam-paper'">My Exam</h5>
            <button nbButton hero outline status="primary" size="small" (click)="onExam()" *ngIf="route == '/exam-paper'">
                {{countDownMin < 10 ? '0' + countDownMin : countDownMin}} :
                {{countDownSec < 10 ? '0' + countDownSec : countDownSec}}
            </button>

        </div>
        <div class="col-3 d-flex justify-content-end align-items-center mt-1 pl-0 pr-0 d-flex justify-content-end">
            <button nbButton hero outline class="exam-button" status="primary" size="small" (click)="onExam()" *ngIf="route == '/' || route == '/home'">Enroll</button>
           
        </div>

    </div>
</div> -->

<!-- Header Starts -->
<header id="header">
  <div class="header-box">
    <div class="container">
      <div class="logoBox">
        <a class="logo" routerLink="/user/dashboard">
          <img src="assets/image/logo.svg" alt="Logo" />
        </a>
      </div>
      <button
        nbButton
        hero
        outline
        status="primary"
        size="small"
        (click)="onExam()"
        *ngIf="route == '/user/exam-paper'"
      >
        {{ countDownMin < 10 ? "0" + countDownMin : countDownMin }} :
        {{ countDownSec < 10 ? "0" + countDownSec : countDownSec }}
      </button>
      <!-- <div class="header-middle">
        <a href="javascript:void(0)" (click)="analytics()"
          >Performance Analysis</a
        >
        <a href="javascript:void(0)" (click)="logout()">Logout</a>
      </div> -->
      <div class="header-right" [ngClass]="{'active' : toggleMenu}" >
        <div class="profile-basic-details" (click)="toggleMenu = !toggleMenu">
          <div class="profile-icon">
            <img src="assets/image/profile-2.png" alt="" />
          </div>
          <div>
            <p class="mr-2">
              {{ dataServiceClass.currentUserGet?.result?.user?.firstname }}
              {{ dataServiceClass.currentUserGet?.result?.user?.lastname }}
            </p>
            <p *ngIf="my_rank" title="My Points">
              {{ my_rank }} Rank & {{ totalScore }} Pts.
            </p>
          </div>
          <div class="down-arrow">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="angle-down"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
              class="svg-inline--fa fa-angle-down fa-w-8"
            >
              <path
                fill="currentColor"
                d="M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z"
                class=""
              ></path>
            </svg>
          </div>
        </div>
        <div class="profile-menu-ct">
          <ul>
            <li (click)="dashboard();toggleMenu = !toggleMenu">
              <a>Dashboard</a>
            </li>
            <li (click)="analytics();toggleMenu = !toggleMenu">
              <a>Reports</a>
            </li>
            <li (click)="logout();toggleMenu = !toggleMenu">
              <a>Logout</a>
            </li>
          </ul>
        </div>

        <!-- <ul class="header-rig">
              <li class="drop">

              </li>
            </ul> -->
      </div>
    </div>
  </div>
</header>
<!-- Header Ends -->
